import React from "react"
import { LayoutLogin } from "../components/layout"
import Seo from "../components/seo"

const Login = () => (
    <>
        <Seo title="Page two" />
        <LayoutLogin />
    </>
)

export default Login
